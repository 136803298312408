export default [
  {
    header: 'Template Admin',
  },
  {
    title: 'Template  Admin',
    icon: 'FileTextIcon',
    route: 'template-list',
    children: [
      {
        title: 'Templates',
        route: 'template-list',
        icon: 'FileTextIcon',
      },
      {
        title: 'Habits',
        route: 'template-habit-list',
        icon: 'ActivityIcon',
      },
      {
        title: 'KPI',
        route: 'template-kpi-list',
        icon: 'ClockIcon',
      },
      {
        title: 'Goals',
        route: 'template-goals-list',
        icon: 'TargetIcon',
      },
      {
        title: 'Charity',
        route: 'template-charity-list',
        icon: 'GiftIcon',
      },
    ],
  },
]
